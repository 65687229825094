import {
  memberAPI,
  deciplusMemberAPI,
  COMMON_URLS
} from '../request';

import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getGlobalAccounts: ({ reinitToken }) => {
    const url = adaptUrl('accounts', {
      queries: {
        reinitToken,
        domain: findDomain()
      }
    });
    return deciplusMemberAPI.get(url);
  },
  getPassedBookings: ({ page }) => {
    const url = adaptUrl('/bookings/passed', { queries: { page } });
    return memberAPI.get(url);
  },
  getDomainUserInfo: () => memberAPI.get('me', { withCredentials: true, params: { domain: findDomain() } }),
  setUserConfig: (name, body) => {
    const url = 'config/:name'.replace(':name', name);
    deciplusMemberAPI.patch(url, body);
  },
  updateDomainUserInfo: (userData) => memberAPI.put('me', userData),
  getClubInfo: () => deciplusMemberAPI.get('clubs'),
  linkClubAccountToGlobalAccount: ({ email, password }) => deciplusMemberAPI.post('clubs/link', { email, password, domain: findDomain() }),
  loadUserProducts: () => memberAPI.get('subscription'),
  getManageWalletUrl: (replaceWallet = false, memberWalletId = null) => {
    const baseUrl = `${COMMON_URLS.BASE_URL}members/v1/wallet/manage`;
    const params = { replaceWallet };
    if(memberWalletId) {
      params.memberWalletId = memberWalletId;
    }
    return `${baseUrl}?${new URLSearchParams(params).toString()}`;
  },
  getWallet: () => {
    return memberAPI.get('wallet');
  },
  getActiveWallets: (includePending) => {
    return memberAPI.get('active-wallets', { params: { includePending} });
  },
  manageWallet: async (returnURL, forceNewWalletId = false, memberWalletId) => {
    const { data } = await memberAPI.post(
      'wallet', { returnURL, forceNewWalletId, memberWalletId: memberWalletId}
    );
    return data;
  },
  deleteWallet: (walletId, cardInd) => {
    return memberAPI.delete('wallet', { data: { walletId, cardInd } });
  },
  getBalance: () => {
    return memberAPI.get('balance');
  },
  getRequirements : () => {
    return memberAPI.get('requirements');
  }
};
